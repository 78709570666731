@tailwind base;
@tailwind components;
@tailwind utilities;


/* purgecss start ignore */
html {
    @apply text-base scroll-smooth;
}

/* Hide Show navigaton on mobile scroll */
header {
    @apply visible;
}

.hide-header {
    header {
        @apply invisible;
    }
}

@screen md {
    .hide-header {
        header {
            @apply visible;
        }

        header > nav {
            @apply translate-y-0;
        }
    }
}

/* Contact Section */
.contact-bg-l {
    transform: translate(-300px, 124px);

    @screen lg {
        transform: translate(-450px, 124px);
    }

}

.contact-bg-r {
    transform: translate(310px, 62px);

    @screen lg {
        transform: translate(450px, 62px);
    }
}

/* FAQs Section */
.js-accordion {
    dd {
        @apply hidden opacity-0 transition-all;

        &.opened {
            @apply block opacity-100;
        }
    }

    button {
        .toggle-arrow {
            @apply transition-all duration-300 transform rotate-0;
        }

        &[aria-expanded="true"] {
            .toggle-arrow {
                @apply -rotate-180;
            }
        }
    }
}

.faqs-bg-l {
    transform: translate(-700px, 76px);

    @screen 2xl {
        transform: translate(-750px, 76px);
    }
}

.faqs-bg-r {
    transform: translate(675px, 204px);

    @screen 2xl {
        transform: translate(775px, 204px);
    }
}

/* Locations */
.storerocket-result-prime-text {
    /* Farmers market tags */
    .storerocket-result-type-10303 & {
        background-color: #FFAC5F;
    }
    /* Large chain tags */
    .storerocket-result-type-10304 & {
        background-color: #007DC7;
    }
    /* Small chain tags */
    .storerocket-result-type-10305 & {
        background-color: #754668;
    }
    /* Fresh truck tags */
    .storerocket-result-type-10306 & {
        background-color: #2CA839;
    }
    /* Wholesale tags */
    .storerocket-result-type-10307 & {
        background-color: #139BB9;
    }
    /* Corner store tags */
    .storerocket-result-type-10308 & {
        background-color: #CB4741;
    }
}

/* Modal */
.modal {
    @apply opacity-0 invisible;

    &.open {
        @apply visible opacity-100;
    }
  }

/* purgecss end ignore */

/* Translate Dropdown Container */
.translate-container {
    position: relative;
    flex: 0 0 auto;
}

/* Google Translate Dropdown Button */
.dropdown-toggle {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 16px;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    box-shadow: none;
    transition: box-shadow 0.3s ease;
}

.dropdown-toggle:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.dropdown-toggle .translate-icon {
    margin-right: 8px;
    height: 24px;
    width: 24px;
}

/* Dropdown Menu */
.google-translate {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 250px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 8px 0;
    z-index: 10;
    transition: opacity 0.4s ease, transform 0.4s ease;
    opacity: 0;
    transform: translateY(-5px);
    pointer-events: none;
}

/* Show Dropdown on Hover */
.translate-container:hover .google-translate,
.google-translate:hover {
    display: block;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

/* Dropdown Menu Items */
.google-translate li {
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.google-translate li a {
    text-decoration: none;
    color: #333;
    display: block;
}

.google-translate li:hover {
    background-color: #f0f4f8;
}

.google-translate li a:hover {
    color: #007bff; /* Blue highlight for hover */
}

.google-translate li .selected .checkmark {
    margin-left: auto;
    color: #007bff; /* Checkmark color */
    font-size: 12px;
}
