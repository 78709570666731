*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.-top-14 {
  top: -3.5rem;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-4 {
  top: 1rem;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.m-auto {
  margin: auto;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-16 {
  height: 4rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-6 {
  width: 1.5rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-sm {
  max-width: 24rem;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.scroll-mt-24 {
  scroll-margin-top: 6rem;
}

.scroll-mt-32 {
  scroll-margin-top: 8rem;
}

.scroll-mt-44 {
  scroll-margin-top: 11rem;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-x-6 {
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.break-all {
  word-break: break-all;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(204 222 205 / var(--tw-border-opacity));
}

.bg-accent-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 255 241 / var(--tw-bg-opacity));
}

.bg-accent-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 236 110 / var(--tw-bg-opacity));
}

.bg-accent-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 255 248 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 222 205 / var(--tw-bg-opacity));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 47 56 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-none {
  background-image: none;
}

.p-6 {
  padding: 1.5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-20 {
  padding-top: 5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-primary {
  font-family: Inter, sans-serif;
}

.font-secondary {
  font-family: Poppins, sans-serif;
}

.text-2xl {
  letter-spacing: -1px;
  font-size: 25px;
  line-height: 33px;
}

.text-3xl {
  letter-spacing: -1px;
  font-size: 31px;
  line-height: 40px;
}

.text-5xl {
  letter-spacing: -1.7px;
  font-size: 45px;
  line-height: 48.5px;
}

.text-base {
  font-size: 16px;
  line-height: 22px;
}

.text-lg {
  font-size: 18px;
  line-height: 25px;
}

.text-sm {
  font-size: 14px;
  line-height: 20px;
}

.text-xl {
  font-size: 21px;
  line-height: 21px;
}

.text-xs {
  font-size: 12px;
  line-height: 17px;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.text-accent-600 {
  --tw-text-opacity: 1;
  color: rgb(44 168 57 / var(--tw-text-opacity));
}

.text-accent-900 {
  --tw-text-opacity: 1;
  color: rgb(4 66 10 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(206 213 215 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(143 143 143 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(28 28 28 / var(--tw-text-opacity));
}

.text-rust {
  --tw-text-opacity: 1;
  color: rgb(204 95 84 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-60 {
  opacity: .6;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  line-height: 22px;
}

header {
  visibility: visible;
}

.hide-header header {
  visibility: hidden;
}

@media (width >= 768px) {
  .hide-header header {
    visibility: visible;
  }

  .hide-header header > nav {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.contact-bg-l {
  transform: translate(-300px, 124px);
}

@media (width >= 1024px) {
  .contact-bg-l {
    transform: translate(-450px, 124px);
  }
}

.contact-bg-r {
  transform: translate(310px, 62px);
}

@media (width >= 1024px) {
  .contact-bg-r {
    transform: translate(450px, 62px);
  }
}

.js-accordion dd {
  opacity: 0;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: none;
}

.js-accordion dd.opened {
  opacity: 1;
  display: block;
}

.js-accordion button .toggle-arrow {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.js-accordion button[aria-expanded="true"] .toggle-arrow {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.faqs-bg-l {
  transform: translate(-700px, 76px);
}

@media (width >= 1536px) {
  .faqs-bg-l {
    transform: translate(-750px, 76px);
  }
}

.faqs-bg-r {
  transform: translate(675px, 204px);
}

@media (width >= 1536px) {
  .faqs-bg-r {
    transform: translate(775px, 204px);
  }
}

.storerocket-result-type-10303 .storerocket-result-prime-text {
  background-color: #ffac5f;
}

.storerocket-result-type-10304 .storerocket-result-prime-text {
  background-color: #007dc7;
}

.storerocket-result-type-10305 .storerocket-result-prime-text {
  background-color: #754668;
}

.storerocket-result-type-10306 .storerocket-result-prime-text {
  background-color: #2ca839;
}

.storerocket-result-type-10307 .storerocket-result-prime-text {
  background-color: #139bb9;
}

.storerocket-result-type-10308 .storerocket-result-prime-text {
  background-color: #cb4741;
}

.modal {
  visibility: hidden;
  opacity: 0;
}

.modal.open {
  visibility: visible;
  opacity: 1;
}

.translate-container {
  flex: none;
  position: relative;
}

.dropdown-toggle {
  text-align: left;
  cursor: pointer;
  box-shadow: none;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  font-size: 16px;
  transition: box-shadow .3s;
  display: flex;
}

.dropdown-toggle:hover {
  box-shadow: 0 4px 10px #0003;
}

.dropdown-toggle .translate-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.google-translate {
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 250px;
  padding: 8px 0;
  list-style: none;
  transition: opacity .4s, transform .4s;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px #0000001a;
}

.translate-container:hover .google-translate, .google-translate:hover {
  opacity: 1;
  pointer-events: auto;
  display: block;
  transform: translateY(0);
}

.google-translate li {
  cursor: pointer;
  padding: 10px 16px;
  font-size: 16px;
  transition: background-color .2s, color .2s;
}

.google-translate li a {
  color: #333;
  text-decoration: none;
  display: block;
}

.google-translate li:hover {
  background-color: #f0f4f8;
}

.google-translate li a:hover {
  color: #007bff;
}

.google-translate li .selected .checkmark {
  color: #007bff;
  margin-left: auto;
  font-size: 12px;
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:ml-10 {
    margin-left: 2.5rem;
  }

  .md\:mr-3 {
    margin-right: .75rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .md\:h-22 {
    height: 5.5rem;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-x-0 {
    -moz-column-gap: 0px;
    column-gap: 0;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:py-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .md\:pb-section {
    padding-bottom: 100px;
  }

  .md\:shadow-map {
    --tw-shadow: -13px 15px 0px -5px #2ca839;
    --tw-shadow-colored: -13px 15px 0px -5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1024px) {
  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }

  .lg\:gap-y-0 {
    row-gap: 0;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-4xl {
    letter-spacing: -1px;
    font-size: 37px;
    line-height: 48px;
  }

  .lg\:text-6xl {
    letter-spacing: -1.7px;
    font-size: 54px;
    line-height: 70px;
  }

  .lg\:text-lg {
    font-size: 18px;
    line-height: 25px;
  }
}
/*# sourceMappingURL=index.7c677a08.css.map */
